/* eslint-disable no-underscore-dangle */
import React from 'react';

function useTranslation(locale: string) {
  const [translations, setTranslations] = React.useState();

  React.useEffect(() => {
    (async function loadLangTranslations() {
      setTranslations((await import(`../utils/lang/${locale}.json`)).default);
    })();
  }, [locale]);

  const __trans = (key: string) => {
    return translations?.[key?.trim()] || key;
  };

  return [__trans];
}

export default useTranslation;
