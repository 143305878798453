import React from 'react';
import { PopupButton } from '@typeform/embed-react';
import { Link, NavLink } from 'react-router-dom';
import { ReactComponent as RiverLogo } from '../../assets/icons/icon_river_logo.svg';
import { paths } from '../../routes';
import { env } from '../../utils/utils';
import Icon from '../Icon/Icon';
import Image from '../Image/Image';
import { OptionButton, OptionItem, OptionList, OptionsMenu } from '../OptionsMenu/OptionsMenu';
import IndividualEnrollPrompt from '../Modal/IndividualEnrollPrompt/IndividualEnrollPrompt';
import { useAppContext } from '../../context/appContext';

type NavbarProps = {
  logout: () => void;
};

function Navbar({ logout }: NavbarProps) {
  const [showMenu, setShowMenu] = React.useState(false);
  const [showIndividualEnrollPrompt, setShowIndividualEnrollPrompt] = React.useState(false);
  const [{ user }] = useAppContext();

  const brokerURL = `${env('RIVER_APP_URL')}/join/broker${
    user?.assignedBrokerCode ? `/${user?.assignedBrokerCode.code}` : ''
  }`;

  return (
    <>
      <header
        className="bg-white shadow-sm shadow-slate-50 border-[0.5px] border-[#e9ecef] flex justify-between
        ring-[#e9ecef] ring-opacity-5 sticky top-0 inset-x-0 z-50 flex-none min-h-[80px] px-6 tablet:px-10 items-center"
      >
        <Link to="/" className="block">
          <RiverLogo className="w-20 tablet:w-16" />
        </Link>
        <button
          type="button"
          className="block tablet:hidden"
          onClick={() => setShowMenu(!showMenu)}
        >
          <Icon id={!showMenu ? 'hamburger' : 'close'} className="text-river-black w-[33px]" />
        </button>
        <ul
          className={`${
            showMenu
              ? 'block absolute bg-white min-w-[300px] top-[80px] right-[40px] !space-x-0 rounded-md p-4 shadow transition-all ease-in-out space-y-3'
              : 'hidden tablet:flex'
          } flex-row space-x-7 items-center text-[15px] text-river-main-gray font-light`}
        >
          <li>
            <NavLink
              to={paths.DASHBOARD_URL_PATH}
              className={({ isActive }) => (isActive ? 'text-black font-semibold' : '')}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to={paths.RESOURCES_URL_PATH}
              className={({ isActive }) => (isActive ? 'text-black font-semibold' : '')}
            >
              Resources
            </NavLink>
          </li>
          <li>
            <NavLink
              to={paths.GROUPS_URL_PATH}
              className={({ isActive }) => (isActive ? 'text-black font-semibold' : '')}
            >
              Group
            </NavLink>
          </li>
          <li>
            <button
              type="button"
              onClick={() => {
                window.open(env('RIVER_SUPPORT_URL'));
              }}
            >
              Get support
            </button>
          </li>
          <li className="relative">
            <OptionsMenu>
              <OptionButton className="hover:opacity-80 bg-river-blue rounded-full w-[90px] h-[34px] text-white text-[15px]">
                Enroll
              </OptionButton>
              <OptionList
                className="!min-w-[200px] mt-3 right-0 rounded-md shadow-[0_0px_15px_0px_rgba(0,0,0,0.08)]
                bg-white border-[0.5px] border-[#e9ecef] ring-[#e9ecef] ring-opacity-5 focus:outline-none
                text-[15px] text-river-deep-gray"
              >
                <OptionItem onClick={() => setShowIndividualEnrollPrompt(true)}>
                  Enroll an Individual
                </OptionItem>
                <OptionItem onClick={() => window.open(env('RIVER_EMPLOYER_APP_URL'))}>
                  Enroll a Group
                </OptionItem>
              </OptionList>
            </OptionsMenu>
          </li>
          <li className="absolute top-0 right-4 tablet:relative tablet:!ml-14">
            <OptionsMenu>
              <OptionButton className="max-w-xs flex items-center">
                <Image
                  className="h-[40px] w-[40px] rounded-full tablet:mr-3"
                  src="image_river_avatar_placeholder.svg"
                  alt="user avatar"
                />
              </OptionButton>
              <OptionList
                className="!min-w-[170px] mt-3 right-0 rounded-md shadow-[0_0px_15px_0px_rgba(0,0,0,0.08)] text-[15px]
                    bg-white border-[0.5px] border-[#e9ecef] ring-[#e9ecef] ring-opacity-5 focus:outline-none
                    text-river-deep-gray"
              >
                <OptionItem className="flex items-center" onClick={logout}>
                  <Icon
                    id="logoutIcon"
                    className="w-5 inline-flex mr-2 text-river-deep-gray group-hover:text-river-blue"
                  />
                  Logout
                </OptionItem>
              </OptionList>
            </OptionsMenu>
          </li>
        </ul>
      </header>

      {showIndividualEnrollPrompt && (
        <IndividualEnrollPrompt
          onCloseModal={() => setShowIndividualEnrollPrompt(false)}
          brokerURL={brokerURL}
        />
      )}
    </>
  );
}

export default Navbar;
