/* eslint-disable no-underscore-dangle */
import React from 'react';
import Icon from '../../components/Icon/Icon';
import {
  OptionButton,
  OptionItem,
  OptionList,
  OptionsMenu,
} from '../../components/OptionsMenu/OptionsMenu';
import resources from '../../utils/resources';
import { env, getLocale, isValidURL, LANGUAGES, setLocale } from '../../utils/utils';
import useTranslation from '../../hooks/useTranslation';
import Input from '../../components/Inputs/Input/Input';
import { Resource } from '../../types/types';
import ResourceFAQsPrompt from '../../components/Modal/ResourceFAQsPrompt/ResourceFAQsPrompt';
import { useAppContext } from '../../context/appContext';

function Resources() {
  const [{ user }] = useAppContext();
  const [dialogContent, setDialogContent] = React.useState<{ title: string; body: string }>();
  const [language, setLanguage] = React.useState(
    () => LANGUAGES.find(fndLang => fndLang.value === getLocale()) || LANGUAGES[0],
  );
  const [filteredResources, setFilteredResources] = React.useState<Resource[] | undefined>(
    undefined,
  );

  const brokerCode = user?.assignedBrokerCode ? `${user?.assignedBrokerCode.code}` : '';

  const brokerURL = `${env('RIVER_APP_URL')}/join/broker/${brokerCode}`;

  const [__trans] = useTranslation(language.value);

  React.useEffect(() => {
    setLocale(language.value);
  }, [language.value]);

  const handleSearch = (value: string) => {
    if (!value || !resources) {
      setFilteredResources(undefined);
      return;
    }

    const filteredSearch = resources
      .map(fndResource => {
        return {
          ...fndResource,
          assets: fndResource.assets
            .map(fndAsset => ({
              ...fndAsset,
              name: __trans(fndAsset.name),
            }))
            .filter(fndAsset => __trans(fndAsset.name).toLowerCase().includes(value.toLowerCase())),
        };
      })
      .filter(fndResource => fndResource.assets.length > 0);

    setFilteredResources(filteredSearch);
  };

  return (
    <>
      <div className="flex flex-col tablet:flex-row justify-between w-full tablet:items-center mb-8">
        <h3 className="uppercase font-semibold text-[20px] leading-[36px] tracking-[0.03em]">
          RESOURCES
        </h3>
        <div className="flex flex-row space-x-3 mt-4 tablet:mt-0 justify-between w-full tablet:w-[40%] items-center">
          <div className="relative w-full">
            <Icon
              id="searchIcon"
              className="absolute text-river-main-gray w-[18px] h-[18px] my-auto top-0 bottom-0
                  left-4 !opacity-90"
            />
            <Input
              name="name"
              placeholder="Search here"
              className="!py-[10px] px-3 rounded-md !pl-[45px] bg-white focus:border-none focus:border-river-black
                  focus:ring-river-black focus:ring-[1.3px] placeholder:!text-river-main-gray placeholder:!font-light
                  placeholder:!opacity-50 focus:!ring-opacity-80 placeholder:!text-sm border border-river-main-gray border-opacity-20"
              onChange={event => handleSearch(event.target.value)}
            />
          </div>

          <OptionsMenu className="shrink-0 !w-[110px] relative">
            <OptionButton
              className="hover:opacity-80 w-full bg-white ring-river-black ring-[1.3px] border-none border-river-black 
              rounded-md py-[10px] px-3 text-river-black font-semibold text-xs flex justify-between items-center"
            >
              <div className="flex items-center justify-center">
                <Icon
                  id={`flag${language.value.toLocaleUpperCase()}`}
                  className="inline mr-2 h-5"
                />
                {language.label}
              </div>
              <Icon
                id="arrowDown"
                className="block ml-2 w-4 h-4 text-river-black fill-river-black shrink-0 stroke-1 stroke-river-black"
              />
            </OptionButton>
            <OptionList
              className="!w-fit mt-3 right-0 rounded-md shadow-[0_0px_15px_0px_rgba(0,0,0,0.08)]
              bg-white border-[0.5px] border-[#e9ecef] ring-[#e9ecef] ring-opacity-5 focus:outline-none text-xs"
            >
              {LANGUAGES.map(fndLanguage => (
                <OptionItem
                  key={fndLanguage.value}
                  className="text-river-black font-normal flex items-center justify-between"
                  onClick={() => {
                    setLanguage(fndLanguage);
                    setFilteredResources(undefined);
                  }}
                >
                  <div
                    className={`flex items-center justify-center ${
                      language.value === fndLanguage.value ? 'font-semibold' : ''
                    }`}
                  >
                    <Icon
                      id={`flag${fndLanguage.value.toLocaleUpperCase()}`}
                      className="inline mr-2 h-5"
                    />
                    {fndLanguage.label}
                  </div>
                  {language.value === fndLanguage.value && (
                    <Icon
                      id="checked"
                      className="block ml-2 w-5 text-river-black fill-river-black shrink-0"
                    />
                  )}
                </OptionItem>
              ))}
            </OptionList>
          </OptionsMenu>
        </div>
      </div>

      <div className="bg-white rounded-sm px-5 pt-5 pb-7 space-y-8 tablet:px-10 tablet:pt-10 tablet:pb-14 tablet:space-y-12">
        {(filteredResources || resources).map((fndResource, index) => (
          <div key={`${fndResource.category.replaceAll(' ', '').toLocaleLowerCase()}${index}`}>
            <div className="flex justify-between w-full items-center mb-4">
              <h3 className="font-semibold text-black uppercase text-[15px] leading-[28px] tracking-[0.03em]">
                {__trans(fndResource.category)}
              </h3>
            </div>
            <div className="w-full">
              <table className="min-w-full divide-y divide-gray-200 border-b overflow-hidden">
                <tbody className="divide-y ">
                  {fndResource.assets.map((fndAsset, assetIndex) => (
                    <tr
                      key={`${fndAsset.name.replaceAll(' ', '').toLocaleLowerCase()}${assetIndex}`}
                      className="w-full  text-left text-[15px] leading-[24px] tracking-[0.03em] text-river-deep-gray"
                    >
                      <td className="px-3 py-3 tablet:px-6 tablet:py-4 w-[5%]">{assetIndex + 1}</td>
                      <td className="px-3 py-3 tablet:px-6 tablet:py-4">
                        {__trans(fndAsset.name)}
                      </td>
                      <td className="px-3 py-3  tablet:px-6 tablet:py-4 text-center w-[10%]">
                        {fndAsset.actionType === 'file' ? (
                          <a
                            href={isValidURL(fndAsset.action) ? fndAsset.action : `#`}
                            target="_blank"
                            download
                            className="text-river-blue font-medium px-4 py-[5px] flex items-center justify-center 
                          bg-white border border-river-blue rounded-full text-xs whitespace-nowrap"
                            rel="noreferrer"
                          >
                            {__trans('Download')}
                            <Icon id="fileDownload" className="w-4 h-4 ml-2" />
                          </a>
                        ) : fndAsset.actionType === 'link' ? (
                          <a
                            href={isValidURL(fndAsset.action) ? fndAsset.action : `#`}
                            target="_blank"
                            className="text-river-blue font-medium px-4 py-[5px] flex items-center justify-center 
                        bg-white border border-river-blue rounded-full text-xs whitespace-nowrap"
                            rel="noreferrer"
                          >
                            {__trans('Open link')}

                            <Icon id="openLink" className="w-4 h-4 ml-2" />
                          </a>
                        ) : (
                          <button
                            type="button"
                            onClick={() => {
                              if (fndAsset.actionType === 'dialog') {
                                setDialogContent({
                                  title: fndAsset.name,
                                  body: fndAsset.action.replace(
                                    '[BROKER_LINK_HERE]',
                                    `[${brokerURL}](${brokerURL})`,
                                  ),
                                });
                              }
                            }}
                            className="text-river-blue font-medium px-4 py-[5px] flex items-center justify-center 
                          bg-white border border-river-blue rounded-full text-xs whitespace-nowrap"
                          >
                            {__trans('View')}
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>

      {dialogContent && (
        <ResourceFAQsPrompt
          content={dialogContent}
          onCloseModal={() => setDialogContent(undefined)}
        />
      )}
    </>
  );
}

export default Resources;
