const resources = [
  {
    category: 'Selling To Employers',
    assets: [
      {
        name: 'Sales Presentation for River Health Plans',
        action: 'https://drive.google.com/file/d/1ah2COrxoRSCab8nQoSIvehwYGAoT9vDo/view',
        actionType: 'link',
      },
      {
        name: 'Sales Presentation for Loon Health Plans',
        action:
          'https://drive.google.com/file/d/1BmHiD10Jawmj3XivrxZuv0_gTggkE4vd/view?usp=sharing',
        actionType: 'link',
      },
      {
        name: 'River Health FAQs',
        action: 'https://drive.google.com/file/d/1MITGPM93PVmGfOSMh7cEZYZc13gvC-Ha/view',
        actionType: 'link',
      },
      {
        name: 'Loon Health FAQs',
        action: 'https://drive.google.com/file/d/1tvd0IqqOkLY9yV_We7XtiCouHS9JeH67/view',
        actionType: 'link',
      },
      {
        name: 'River Health Pricing',
        action:
          'https://drive.google.com/file/d/1KIYuWeMnNBk419w2aB58AQXkxv-l87-Z/view?usp=sharing',
        actionType: 'link',
      },
      {
        name: 'Loon Health Pricing',
        action:
          'https://drive.google.com/file/d/120Szwuz93XDplZ4ytPRvmJc6Sa_5SAWl/view?usp=sharing',
        actionType: 'link',
      },
    ],
  },
  {
    category: 'Selling To Individuals',
    assets: [
      {
        name: 'River Health Starter Plan Benefits Summary',
        action:
          'https://drive.google.com/file/d/1wJPBTDkAK7tJ4CKQp2UvBYXyUHxgNJNr/view?usp=sharing',
        actionType: 'link',
      },
      {
        name: 'River Health Complete Plan Benefits Summary',
        action:
          'https://drive.google.com/file/d/1Asr18IPjOGsdkj-si_uNKrG0gSB1xlna/view?usp=sharing',
        actionType: 'link',
      },
    ],
  },
  {
    category: 'How To Use River Health Services',
    assets: [
      {
        name: 'How To See A Doctor Online',
        action: 'https://vimeo.com/riverhealth/virtual-visits',
        actionType: 'link',
      },
      {
        name: 'How To Get Your Prescriptions',
        action: 'https://vimeo.com/riverhealth/prescriptions',
        actionType: 'link',
      },
      {
        name: 'River Health Summary Video',
        action: 'https://vimeo.com/riverhealth/what-is-river',
        actionType: 'link',
      },
      {
        name: 'Getting Mental Health Services',
        action: 'https://vimeo.com/riverhealth/behavioral-health',
        actionType: 'link',
      },
    ],
  },
  {
    category: 'Resources for Brokers',
    assets: [
      {
        name: 'How to submit an employer application',
        action: `To submit an application on behalf of an employer, please visit [employer.helloriver.com](https://employer.helloriver.com) to begin the application process. You will need the following to complete an application on behalf of an employer or group:

        1. Legal name
        2. Employer Identification Number
        3. Address
        4. Number of employees
        5. Plan Type Selection
        6. Contribution amount
        7. Plan start date
        8. Eligibility file.
        `,
        actionType: 'dialog',
      },
      {
        name: 'How to enroll members under a group',
        action: `To add a member to an active group or an employer account 
        on River Health, please send an enrollment file to team@helloriver.com 
        with the individual’s name, phone, date of birth, address, gender, 
        and email. `,
        actionType: 'dialog',
      },
      {
        name: 'How to submit an application for an individual',
        action: `To sign someone up for a River Health plan, share your special broker 
        link with them. You can also use this link to enroll them yourself. 
        Just have their name, birth date, address, gender, phone number, 
        and payment details ready.`,
        actionType: 'dialog',
      },
      {
        name: 'How to find your unique broker link',
        action: `Your unique broker enrollment link is available on the broker portal 
        homepage. You can also find it here: [BROKER_LINK_HERE]`,
        actionType: 'dialog',
      },
      {
        name: 'How to get support',
        action: `Our River Health broker support team is available to assist you. 
        If you have questions or concerns, please send an email to [brokers@helloriver.com](mailto:brokers@helloriver.com)  
        or call River Health at [(888) 814-6062](tel:8888146062).`,
        actionType: 'dialog',
      },
    ],
  },
];

export default resources;
