import React from 'react';
import { RouteObject } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import Dashboard, { loader as dashboardLoader } from './pages/Dashboard/Dashboard';
import Login, { action as loginAction } from './pages/Login/Login';
import Resources from './pages/Resources/Resources';
import Groups, { loader as groupLoader } from './pages/Groups/Groups';
import Layout, { loader as layoutLoader } from './components/Layout/Layout';
import Members, { loader as groupMembersLoader } from './pages/Members/Members';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

export const paths = {
  AUTH_URL_PATH: '/login',
  DASHBOARD_URL_PATH: '/',
  RESOURCES_URL_PATH: '/resources',
  GROUPS_URL_PATH: '/groups',
  GROUP_MEMBERS_URL_PATH: '/groups/:groupId/:company',
};

const routes = (queryClient: QueryClient) =>
  [
    {
      path: paths.AUTH_URL_PATH,
      element: <Login />,
      action: loginAction(queryClient),
    },
    {
      path: paths.DASHBOARD_URL_PATH,
      element: <Layout />,
      loader: layoutLoader(queryClient),
      errorElement: <ErrorBoundary />,
      children: [
        {
          errorElement: <ErrorBoundary />,
          children: [
            {
              index: true,
              element: <Dashboard />,
              loader: dashboardLoader(queryClient),
            },
            {
              path: paths.RESOURCES_URL_PATH,
              element: <Resources />,
            },
            {
              path: paths.GROUPS_URL_PATH,
              element: <Groups />,
              loader: groupLoader(queryClient),
            },
            {
              path: paths.GROUP_MEMBERS_URL_PATH,
              element: <Members />,
              loader: groupMembersLoader(queryClient),
            },
          ],
        },
      ],
    },
  ] satisfies RouteObject[];

export default routes;
