import React from 'react';
import Modal from '../Modal';
import MarkdownHTML from '../../MarkdownHTML/MarkdownHTML';

type ModalProps = {
  content: {
    title: string;
    body: string;
  };
  onCloseModal: () => void;
};

function ResourceFAQsPrompt({ onCloseModal, content }: ModalProps) {
  return (
    <Modal onClose={onCloseModal} show>
      <div className="rounded-xl bg-white tablet:w-[600px] laptop:w-[800px] tablet:min-h-[400px]">
        <div className="flex flex-col px-6 tablet:px-12 py-10 items-center">
          <h3 className="font-semibold text-black uppercase text-lg leading-[28px] tracking-[0.03em] mb-3 text-center">
            {content.title}
          </h3>

          <div className="resource">
            <MarkdownHTML className="text-river-black text-sm" content={content.body} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ResourceFAQsPrompt;
